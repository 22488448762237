import { Controller } from "stimulus";
import moment from 'moment'
//import http from "@/services/http";

export default class extends Controller {
  static targets = ["input", "list"];
  static values = { commentableId: Number }

  buildComment(data) {
    const newComment = document.createElement("div");
    const p = `<p class="whitespace-pre-line">${data.text}</p>`
    const footer = `
      <footer class="text-right">
        ${data.user} &mdash;
        <time datetime="${data.created_at}" class="text-small">
          ${moment.utc(data.created_at).from()}
        </time>
      </footer>`

    newComment.classList.add("comment");
    newComment.innerHTML = `${p}${footer}`;
    newComment.dataset["id"] = data.id;

    return newComment;
  }

  fetchComments() {
    return fetch(`/deals/${this.commentableIdValue}/comments`)
      .then(resp => resp.json())
      .then(comments => {
        for (let comment of comments) {
          this.appendComment(this.buildComment(comment))
        }
      })
      .catch(err => {
        console.error(err)
        throw err
      })
  }

  appendComment(comment) {
    if (!this.listTarget.querySelector(`[data-id="${comment.dataset.id}"]`)) {
      this.listTarget.append(comment);
    }
  }

  toggle(ev) {
    ev.preventDefault();
    ev.stopPropagation();

    if (this.element.classList.contains("open")) {
      this.element.classList.remove("open");
    } else {
      this.element.classList.add("open");
      this.fetchComments()
    }
  }
}
