import { Controller } from 'stimulus'
import debounce from '@/services/debounce'

const LOAD_PREVIEW_DEBOUNCE = 850

export default class extends Controller {
  static targets = ['container', 'input', 'reset']
  static values = {
    previewClass: String
  }

  previewImages;

  connect() {
    this.previewImages = []

    this.inputTarget.addEventListener('change', this.readFiles.bind(this))
    this.resetTarget.addEventListener('click', this.resetPreviews.bind(this))
  }

  readFiles(ev) {
    let files = ev.target.files,
      i = 0
    this.previewImages = []
    const callRender = debounce(() => { this.renderPreviews() }, LOAD_PREVIEW_DEBOUNCE)

    for (i = 0; i < files.length; ++i) {
      const reader = new FileReader()

      reader.addEventListener('load', (ev) => {
        this.previewImages.push(ev.target.result)
        callRender()
      })

      reader.readAsDataURL(files.item(i))
    }
  }

  renderPreviews() {
    this.containerTarget.innerHTML = ''
    let newHTML = ''

    for (let parsedFile of this.previewImages) {
      newHTML += `<figure><img src="${parsedFile}" class="${this.previewClassValue}" /></figure>`
    }

    this.containerTarget.innerHTML = newHTML
  }

  resetPreviews() {
    this.previewImages = []
    this.containerTarget.innerHTML = ''
  }
}
