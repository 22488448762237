import { Controller } from 'stimulus'
import moment from 'moment'

/**
 * Stimulus v2 Controller for automatic timestamp operations
 *
 * @see https://web.archive.org/web/20201111225017/https://stimulusjs.org/handbook/building-something-real
 */
export default class extends Controller {
  static targets = ['time']
  static values = {}

  connect() {
    const createdAt = this.timeTarget?.dateTime?.replace(' UTC', '')

    if (createdAt) {
      this.timeTarget.textContent = moment.utc(createdAt).locale('es').from()
    }
  }
}
