import { Controller } from 'stimulus'
import $ from 'jquery'
import 'select2'

export default class extends Controller {
  static targets = []
  static values = {
    processor: String,
    query: String,
    src: String,
  }

  static PROCESSORS = {
    deals(data) {
      return {
        results: data.offers.map(el => ({id: el[0], text: el[1]}))
      };
    },

    tags(data) {
      return { results: data.tags.map(tag => ({id: tag, text: tag})) };
    },
  }

  connect() {
    const select2Options = {
      templateSelection: this.optgroupText,
    }

    if (this.srcValue) {
      select2Options.ajax = {
        data: (params) => {
          return {
            [this.queryValue]: params.term,
            term: params.term,
          };
        },
        delay: 300,
        url: this.srcValue,
        processResults: this.resultProcessor(),
      }
    }

    $(this.element).select2(select2Options)
  }

  resultProcessor() {
    if (!Object.keys(this.constructor.PROCESSORS).includes(this.processorValue)) return this.constructor.PROCESSORS.deals;

    return this.constructor.PROCESSORS[this.processorValue];
  }

  optgroupText(option) {
    if (option.element.parentElement.tagName == 'OPTGROUP') {
      let text = $('<div><span class="option-text"></span> <span class="smaller option-group"></span></div>')

      text.find('.option-text').text(option.text)
      text.find('.option-group').text(`(${option.element.parentElement.label})`)

      return text
    }

    return option.text
  }
}
