import { Controller } from 'stimulus'
import http from '@/services/http'

export default class extends Controller {
  static targets = ['main', 'secondary']
  static values = {}

  connect() {
    if (this.mainTarget.value) {
      this.fetchSubmaterials()
    }
  }

  fetchSubmaterials(ev) {
    const queryString = {
      main: this.mainTarget.value
    }

    http.get('/catalogues/submaterials', { params: queryString })
      .then(resp => {
        let option = null
        const options = resp.data
          .map(el => {
            option = document.createElement('option')

            option.value = el.id
            option.textContent = el.secondary
            if (el.id == this.selectedSecondaryMaterial) {
              option.selected = true
            }

            return option
          })

        this.secondaryTarget.innerHTML = null
        options.forEach(opt => this.secondaryTarget.appendChild(opt))
        this.secondaryTarget.disabled = false
      })
      .catch(err => {
        console.error('fetchSubmaterials:', err)
      })
  }

  get selectedSecondaryMaterial() {
    return (new URL(document.location)).searchParams.get('material_id')
  }
}
