import { Controller } from 'stimulus'
import http from '@/services/http'

const REMOVE_BUTTON_SELECTOR = '.remove-offer-image-button'

export default class extends Controller {
  static targets = []
  static values = {
    offerId: String,
  }

  connect() {
    this.element.querySelectorAll(REMOVE_BUTTON_SELECTOR).forEach(el => {
      el.addEventListener('click', this.removeImage.bind(this))
    })
  }

  removeImage(ev) {
    const button = ev.target
    const imageId = button.dataset['imageId']
    const figure = button.parentElement

    if (!imageId) {
      console.error('OfferImages#removeImage: missing image id')
      return
    }

    this.deleteImage(this.offerIdValue, imageId)
      .then(resp => {
        figure.parentElement.removeChild(figure)
      })
      .catch(err => {
        console.error('OfferImages#removeImage:', err)
      })
  }

  deleteImage(offerId, imageId) {
    return http.delete(`/offers/${offerId}/assets/${imageId}`)
  }
}
