import $ from "jquery"
import "select2"
import { Controller } from "stimulus"
import { MapService, SUGGESTIONS_PATH } from "@/services/map_service"

const DEFAULT_TIMEOUT = 3000
const DEBOUNCE_TIMEOUT = 300

export default class extends Controller {
  static targets = [
    "city",
    "country",
    "lonlat",
    "map",
    "panel",
    "query",
    "state",
    "street",
    "zipCode"
  ]
  static values = {
    mapKey: String,
    suggestions: String,
  }

  map;
  platform;
  timeoutId;

  initialize() {
    this.setupMap(this.setupHere(this.mapKeyValue))
  }

  /**
   * Setup HERE Platform
   */
  setupHere(apiKey) {
    if (window.H) {
      this.platform = new H.service.Platform({ apiKey, })
      return this.platform
    } else {
      console.error("Could not load Here map: H is undefined")
      return null
    }
  }

  /**
   * Visual components **MUST** be initialized after the component has
   * been mounted/rendered.
   *
   * We use a timeout because the map tiles loading fails whenever the user
   * visits a page with this component first, probably because the loading of
   * the HERE assets is not ready.
   */
  setupMap(platform) {
    window.setTimeout((ev) => {
      if (platform) {
        let layers = platform.createDefaultLayers()
        let opts = {
          center: {lat: 25.788416, lng: -100.3159552},
          zoom: 5,
        }

        this.map = new H.Map(this.mapTarget, layers.vector.normal.map, opts)
        this.mapEvents = new H.mapevents.MapEvents(this.map)
        this.behavior = new H.mapevents.Behavior(this.mapEvents)
        this.ui = H.ui.UI.createDefault(this.map, layers)
        this.mapService = new MapService(this.map, this.ui, this.panelTarget)
        this.icons = this.mapService.setupIcons()
        this.setupAddressSearch()
      } else {
        console.error("Failed to initialize map.")
      }
    }, DEFAULT_TIMEOUT)
  }

  setupAddressSearch() {
    $(this.queryTarget).select2({
      minimumInputLength: 8,
      placeholder: "Busqueda de dirección...",
      ajax: {
        data(params) {
          return {
            query: params.term,
          }
        },
        dataType: "json",
        delay: DEBOUNCE_TIMEOUT,
        url: this.suggestionsValue,
        processResults: (data) => {
          return {
            results: data.suggestions
              .map(el => ({...el, id: el.label, text: this.mapService.genAddressCustomLabel(el.address)}))
          }
        },
      },
    })

    $(this.queryTarget).on("select2:select", (ev) => {
      const data = ev.params.data

      console.debug('setupAddress: select changed', data.address)
      this.countryTarget.value = data.address.country_id
      $(this.stateTarget).val(data.address.state_id)
      $(this.stateTarget).trigger("change")
      this.cityTarget.value = data.address.city
      this.streetTarget.value = `${data.address.street} ${data.address.houseNumber}`
      this.zipCodeTarget.value = data.address.postalCode
      this.addAddressBubble(data)
    })
  }

  addAddressBubble(data) {
    this.platform.getSearchService().geocode({q: data.label}, (resp) => {
      let item = resp.items[0]

      this.mapService.addBubble(item.position, data.text, true)
      this.lonlatTarget.value = `POINT(${item.position.lng} ${item.position.lat})`
    }, (err) => {
      console.error('suggestionSelected:', err)
    })
  }
}
